import {
  Box,
  Button,
  Center,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import { getSingleParty, updateParty } from "../services/axios.services";

interface Party {
  name: string;
  phone: string;
  email: string;
  partyType: string;
  image?: string;
}

export default function EditParty() {
  const token = useSelector((state: RootState) => state.user.token);
  const { id } = useParams();
  const navigate = useNavigate();

  const [partyImage, setPartyImage] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Party>();

  const onSubmit = async (data: Party) => {
    const response = await updateParty(
      {
        id: id,
        name: data.name,
        phone: data.phone,
        email: data.email,
        partyType: data.partyType,
        image: partyImage,
      },
      token as string
    );
    if (response.success) {
      navigate(-1);
    }
  };

  const handleImageConvertToBase64 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (!file) {
      console.error("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPartyImage(reader.result as string);
    };
  };

  const fetchPartyData = async (id: string, token: string) => {
    const response = await getSingleParty(id, token);
    console.log("response -> ", response);
    if (response.success) {
      reset({
        name: response.data.name,
        email: response.data.email,
        partyType: response.data.partyType,
        phone: response.data.phone,
      });
      setPartyImage(`${response.data.image}`);
    }
  };

  useEffect(() => {
    fetchPartyData(id as string, token as string);
  }, []);

  return (
    <Box p={8}>
      <Heading as={"h3"} size={"lg"} mb={5}>
        Edit Party
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={"1fr 1fr"} gap={5}>
          <GridItem>
            <FormLabel>
              Name
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("name", {
                required: "Name is required",
                maxLength: {
                  value: 80,
                  message: "Maximum length is 80 characters",
                },
              })}
            />
            {errors.name && <Text color={"tomato"}>{errors.name.message}</Text>}
          </GridItem>
          <GridItem>
            <FormLabel>
              Phone
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="number"
              {...register("phone", {
                required: "Phone is required",
              })}
            />
            {errors.phone && (
              <Text color={"tomato"}>{errors.phone.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Email
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("email", {
                required: "Email is required",
              })}
            />
            {errors.email && (
              <Text color={"tomato"}>{errors.email.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel mb={3}>
              Type
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Select
              placeholder="Select type"
              {...register("partyType", {
                required: "Party type is required",
              })}
            >
              <option value="agent">Agent</option>
              <option value="reseller">Reseller</option>
            </Select>
          </GridItem>
          <GridItem>
            <VStack
              bg="gray.100"
              px={2}
              py={3}
              borderRadius="md"
              boxShadow="lg"
              spacing={6}
              w="xs"
              maxW="s"
            >
              <Heading size="md" mb={2}>
                Upload Image
              </Heading>
              <Box>
                {partyImage ? (
                  <Image
                    src={partyImage.startsWith("public") 
                      ? `${process.env.REACT_APP_BASE_URL}/${partyImage}` 
                      : partyImage}
                    alt="Preview"
                    maxW="130px"
                    maxH="130px"
                  />
                ) : (
                  <Text color="gray.500">No image selected</Text>
                )}
              </Box>
              <Input
                type="file"
                accept="image/*"
                onChange={(event) => handleImageConvertToBase64(event)}
                variant="filled"
              />
            </VStack>
          </GridItem>
        </Grid>
        <Center>
          <Button type="submit" colorScheme="blue" mt={20} mb={5} px={20}>
            Update Party
          </Button>
          <Button
            type="submit"
            colorScheme="teal"
            color="white"
            ml={2}
            mt={20}
            mb={5}
            px={20}
          >
            Cancel
          </Button>
        </Center>
      </form>
    </Box>
  );
}
