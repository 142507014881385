import { Box, Flex, Text } from "@chakra-ui/react";

interface DashboardCardProps {
  title: string;
  value: string;
  qty: string;
  bgColor: string;
}

export default function DashboardCard({
  title,
  value,
  qty,
  bgColor,
}: DashboardCardProps) {
  return (
    <Box px={4} py={8} bg={bgColor} borderRadius="md" boxShadow="md">
      <Flex alignItems="center">
        <Box ml={4}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {title}
          </Text>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            {value}
          </Text>
          <Text fontSize="sm">Orders QTY {qty}</Text>
        </Box>
      </Flex>
    </Box>
  );
}
