import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LogoState {
  logo: string | null;
}

const initialState: LogoState = {
  logo: null,
};

const logoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {
    setLogo: (state, action: PayloadAction<{ logo: string }>) => {
      state.logo = action.payload.logo;
    },
  },
});

export const { setLogo } = logoSlice.actions;
export default logoSlice.reducer;
