import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaCalendarDay } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import DashboardCard from "../components/DashboardCard/DashboardCard";
import { RootState } from "../redux/store";
import { getDashboardData } from "../services/axios.services";

export default function Dashboard() {
  const token = useSelector((state: RootState) => state.user.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDateRange, setSelectedDateRange] = useState<string>("");
  const [data, setData] = useState<any>();

  const fetchDashboardData = async (startDate: string, endDate: string) => {
    const response = await getDashboardData(
      startDate,
      endDate,
      token as string
    );
    console.log("response -> ", response);
    setData(response);
  };

  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toISOString().slice(0, 10);
    fetchDashboardData(formattedDate, formattedDate);
  }, []);

  useEffect(() => {
    const date = new Date();
    if (selectedDateRange === "Today") {
      const formattedDate = date.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedDate);
    } else if (selectedDateRange === "Yesterday") {
      date.setDate(date.getDate() - 1);
      const formattedDate = date.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedDate);
    } else if (selectedDateRange === "Last 7 Days") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 7);
      const formattedDate = newDate.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedToday);
    } else if (selectedDateRange === "Last Month") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 30);
      const formattedDate = newDate.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedToday);
    } else if (selectedDateRange === "Last 3 Months") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - 3);
      const formattedDate = newDate.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedToday);
    } else if (selectedDateRange === "Last Year") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() - 1);
      const formattedDate = newDate.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedToday);
    } else if (selectedDateRange === "Lifetime") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date(2024, 0, 1);
      const formattedDate = newDate.toISOString().slice(0, 10);
      fetchDashboardData(formattedDate, formattedToday);
    }
  }, [selectedDateRange]);

  const handleMenuClick = (range: string) => {
    setSelectedDateRange(range);
    onClose();
  };

  return (
    <Box>
      <Center mt={5}>
        <Heading size={"lg"}>Order Overview</Heading>
      </Center>
      <Menu isOpen={isOpen}>
        <MenuButton
          as={Button}
          leftIcon={isOpen ? <IoIosCloseCircle /> : <FaCalendarDay />}
          onClick={isOpen ? onClose : onOpen}
          variant="outline"
          size="md"
          pos={"absolute"}
          right={"25px"}
          top={"100px"}
        >
          {selectedDateRange || "Select Date Range"}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => handleMenuClick("Today")}>Today</MenuItem>
          <MenuItem onClick={() => handleMenuClick("Yesterday")}>
            Yesterday
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("Last 7 Days")}>
            Last 7 Days
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("Last Month")}>
            Last Month
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("Last 3 Months")}>
            Last 3 Months
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("Last Year")}>
            Last Year
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => handleMenuClick("Lifetime")}>
            Lifetime
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("Select Date Range")}>
            Select Date Range
          </MenuItem>
        </MenuList>
      </Menu>
      <Grid templateColumns={"1fr 1fr 1fr"} gap={5} px={5} mt={10}>
        <GridItem>
          <DashboardCard
            title="All orders"
            value={`TK ${data?.allOrders?.totalPayablePrice || 0}`}
            qty={data?.allOrders?.orderCount || 0}
            bgColor="white"
          />
        </GridItem>
        <GridItem>
          <DashboardCard
            title="Canceled Order Value"
            value={`TK ${data?.cancelOrders?.totalPayablePrice || 0}`}
            qty={data?.cancelOrders?.orderCount || 0}
            bgColor="red.100"
          />
        </GridItem>
        <GridItem>
          <DashboardCard
            title="Delivered Order Value"
            value={`Tk ${data?.deliveredOrders?.totalPayablePrice || 0}`}
            qty={data?.deliveredOrders?.orderCount || 0}
            bgColor="green.100"
          />
        </GridItem>
        <GridItem>
          <DashboardCard
            title="Return Order Value"
            value={`TK ${data?.returnOrders?.totalPayablePrice || 0}`}
            qty={data?.returnOrders?.orderCount || 0}
            bgColor="yellow.100"
          />
        </GridItem>
        <GridItem>
          <DashboardCard
            title="Pending Order Value"
            value={`TK ${data?.pendingOrders?.totalPayablePrice || 0}`}
            qty={data?.pendingOrders?.orderCount || 0}
            bgColor="purple.100"
          />
        </GridItem>
        <GridItem>
          <DashboardCard
            title="Confirm Order Value"
            value={`TK ${data?.confirmOrders?.totalPayablePrice || 0}`}
            qty={data?.confirmOrders?.orderCount || 0}
            bgColor="blue.100"
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
