import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserData } from "../redux/features/userSlice";
import { RootState } from "../redux/store";
import { handleLogin } from "../services/axios.services";

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const token = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const [showPassword, setShowPassword] = React.useState(false);

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const userData = await handleLogin(data.email, data.password);
      dispatch(setUserData({ email: data.email, token: userData.data.token }));
      toast(userData.data.message);
      navigate("/dashboard");
    } catch (err: any) {
      console.log("error -> ", err.message);
      toast(err.message);
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Stack minH="100vh" direction={{ base: "column", md: "row" }}>
      <Box
        flex={2.5}
        display={{ base: "none", md: "block" }}
        bgImage="url('/assets/login.jpg')"
        bgSize="cover"
        bgPosition="center"
      />
      <Stack
        flex={0.8}
        p={20}
        spacing={4}
        justify="center"
        bg={useColorModeValue("white", "gray.800")}
      >
        <Text
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
          color={useColorModeValue("blue.600", "white")}
        >
          Welcome Back!
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <Text fontWeight={"bold"} mb={1} ml={1}>
              Email Address
            </Text>
            <Input
              type="email"
              variant="outline"
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <Text color={"tomato"}>{errors.email.message}</Text>
            )}
          </Box>
          <Box>
            <Text fontWeight={"bold"} mb={1} ml={1}>
              Password
            </Text>
            <Box position="relative">
              <Input
                type={showPassword ? "text" : "password"}
                variant="outline"
                placeholder="Enter your password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 4,
                    message: "Password must be at least 4 characters",
                  },
                })}
              />
              <IconButton
                aria-label={showPassword ? "Hide password" : "Show password"}
                icon={showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
                variant="link"
                onClick={() => setShowPassword(!showPassword)}
              />
            </Box>
            {errors.password && (
              <Text color={"tomato"}>{errors.password.message}</Text>
            )}
          </Box>
          <Button
            mt={4}
            colorScheme="blue"
            variant="solid"
            width="full"
            type="submit"
          >
            Log In
          </Button>
        </form>
      </Stack>
    </Stack>
  );
};

export default Login;
