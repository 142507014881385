export const languages = [
  { name: "বাংলা" },
  { name: "ইংরেজি" },
  { name: "আরবী" },
  { name: "উর্দু" },
  { name: "বাংলা ও ইংরেজি" },
  { name: "বাংলা ও আরবী" },
  { name: "হিন্দি" },
  { name: "বাংলা, আরবী, উর্দু" },
  { name: "আরবী, উর্দু" },
  { name: "ফার্সি" },
  { name: "বাংলা ও কোরিয়ান" },
  { name: "আরবী এবং ইংরেজি" },
  { name: "ফার্সি ও উর্দু" },
  { name: "বাংলা, ইংরেজি, আরবী" },
  { name: "আরবী, উর্দু, বাংলা, ইংরেজি" },
  { name: "অন্যান্য ভাষা" },
  { name: "স্পানিশ" },
];
