import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import {
  getPartyOrderList,
  handleAuthorListActions,
} from "../services/axios.services";

interface PartyOrder {
  _id: string;
  serialNumber: string;
  updatedAt: string;
  payablePrice: number;
  party: any;
  products: any;
}

export default function PartyList() {
  const token = useSelector((state: RootState) => state.user.token);
  const [partyOrderList, setPartyOrderList] = useState<PartyOrder[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allParty, setAllParty] = useState<PartyOrder[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [typeOfAction, setTypeOfAction] = useState<string>("");
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const navigate = useNavigate();

  const fetchParty = async () => {
    const response = await getPartyOrderList(token as string);
    console.log("response => ", response.orders);
    setPartyOrderList(response.orders);
  };

  useEffect(() => {
    fetchParty();
  }, []);

  const {
    isOpen: isOrderDetailsOpen,
    onOpen: onOrderDetailsOpen,
    onClose: onOrderDetailsClose,
  } = useDisclosure();

  const columns: ColumnDef<PartyOrder, any>[] = [
    {
      header: "Serial",
      accessorKey: "serialNumber",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.serialNumber}</Text>
          <Text>
            {row.original.updatedAt &&
              new Date(row.original.updatedAt).toLocaleString()}
          </Text>
        </Box>
      ),
    },
    {
      header: "Total Payable",
      accessorKey: "payablePrice",
    },
    {
      header: "Due Price",
      accessorKey: "duePrice",
    },
    {
      header: "Payment",
      accessorKey: "paymentStatus",
    },
    {
      header: "Party Details",
      cell: ({ row }) => (
        <Box>
          <Text>
            {row.original.party.name} ({row.original.party.partyType})
          </Text>
          <Text>{row.original.party.phone}</Text>
        </Box>
      ),
    },
    {
      header: "Commission",
      accessorKey: "partyOrderCommissionPrice",
    },
    {
      header: "Products",
      cell: ({ row }) => (
        <Box>
          {row.original.products.map((product: any, index: number) => (
            <Text>
              {index + 1}. {product.nameBangla}
            </Text>
          ))}
        </Box>
      ),
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Box>
          <IconButton
            aria-label="View Product"
            icon={<FaEye />}
            onClick={() => {
              onOrderDetailsOpen();
              setSelectedOrder(row.original);
            }}
          />
        </Box>
      ),
    },
  ];

  const table = useReactTable({
    data: partyOrderList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const handleActions = async () => {
    const reqBody = {
      id: selectedId,
      isActive: typeOfAction === "Make Inactive" ? "false" : "true",
    };

    console.log(reqBody);
    const response = await handleAuthorListActions(reqBody, token as string);
    if (response?.success) {
      toast.success(response.message);
      fetchParty();
      onClose();
    }
  };

  console.log("selected order -> ", selectedOrder);

  return (
    <Box>
      <Heading as={"h3"} size={"lg"} my={5} ml={5}>
        Party Order List
      </Heading>
      <Modal
        isOpen={isOrderDetailsOpen}
        onClose={onOrderDetailsClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={"1fr 1fr"} gap={2}>
              <GridItem>
                <Text>Serial: {selectedOrder?.serialNumber}</Text>
                {/* <Text>Customer Name: {selectedOrder?.customer.name}</Text>
                <Text>Phone: {selectedOrder?.customer.phone}</Text> */}
                <Text>
                  Address: {selectedOrder?.addressInfo.details},{" "}
                  {selectedOrder?.addressInfo.upazila},{" "}
                  {selectedOrder?.addressInfo.details}
                </Text>
              </GridItem>
              <GridItem>
                {/* <Text>Status: {selectedOrder?.currentStatus}</Text> */}
                <Text>Payment Status: {selectedOrder?.paymentStatus}</Text>
                {/* <Text>Total Payable: {selectedOrder?.payablePrice}</Text> */}
                <Text>
                  Order Time:{" "}
                  {selectedOrder?.createdAt &&
                    new Date(selectedOrder.createdAt).toLocaleString()}
                </Text>
              </GridItem>
              {selectedOrder?.products?.map((product: any) => (
                <GridItem colSpan={2}>
                  <Grid templateColumns={"150px 1fr"} gap={5} mt={3}>
                    <GridItem>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${product?.image}`}
                        alt="Product"
                        style={{ width: "150px", height: "230px" }}
                      />
                    </GridItem>
                    <GridItem>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Name(BN):{" "}
                        </Text>
                        {product?.nameBangla}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Name(EN):
                        </Text>{" "}
                        {product?.nameEnglish}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Authors:
                        </Text>{" "}
                        {product?.authors
                          ?.map((item: any) => item?.nameBangla)
                          .join(", ")}
                      </Text>
                      {product?.translators?.length > 0 && (
                        <Text>
                          <Text as={"span"} fontWeight={"bold"}>
                            Translators:
                          </Text>{" "}
                          {product?.translators
                            ?.map((item: any) => item?.nameBangla)
                            .join(", ")}
                        </Text>
                      )}
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Unit Price:
                        </Text>{" "}
                        {product?.regularPrice}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Ordered Quantity:
                        </Text>{" "}
                        {product?.quantity}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Total Price:
                        </Text>{" "}
                        {product?.regularTotalPrice}
                      </Text>
                    </GridItem>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onOrderDetailsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{typeOfAction}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to {typeOfAction} this author?
          </ModalBody>

          <ModalFooter gap={3}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleActions}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
