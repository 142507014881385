import {
  Box,
  Button,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import {
  getPartyList,
  handleAuthorListActions,
} from "../services/axios.services";
import { useNavigate } from "react-router-dom";

interface Party {
  _id: string;
  name: string;
  email: string;
  image: string;
  partyType: string;
  phone: string;
  isActive: boolean;
}

export default function PartyList() {
  const token = useSelector((state: RootState) => state.user.token);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allParty, setAllParty] = useState<Party[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [typeOfAction, setTypeOfAction] = useState<string>("");

  const navigate = useNavigate();

  const fetchParty = async () => {
    const partyList = await getPartyList(token as string);
    console.log("all authors -> ", partyList);
    setAllParty(partyList);
  };

  useEffect(() => {
    fetchParty();
  }, []);

  const columns: ColumnDef<Party, any>[] = [
    {
      header: "Image",
      cell: ({ row }) => (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${row.original.image}`}
          alt="Image"
          width={50}
          height={50}
        />
      ),
    },
    {
      header: "Name",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.name}</Text>
        </Box>
      ),
    },
    {
      header: "Email & Phone",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.email}</Text>
          <Text>{row.original.phone}</Text>
        </Box>
      ),
    },
    {
      header: "Type",
      accessorKey: "partyType",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Box>
          <IconButton
            ml={1}
            aria-label="Edit Product"
            icon={<FaEdit />}
            onClick={() => {
              navigate(`/edit-party/${row.original._id}`);
            }}
          />
        </Box>
      ),
    },
  ];

  const table = useReactTable({
    data: allParty,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const handleActions = async () => {
    const reqBody = {
      id: selectedId,
      isActive: typeOfAction === "Make Inactive" ? "false" : "true",
    };

    console.log(reqBody);
    const response = await handleAuthorListActions(reqBody, token as string);
    if (response?.success) {
      toast.success(response.message);
      fetchParty();
      onClose();
    }
  };

  return (
    <Box>
      <Heading as={"h3"} size={"lg"} my={5} ml={5}>
        Party List
      </Heading>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{typeOfAction}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to {typeOfAction} this author?
          </ModalBody>

          <ModalFooter gap={3}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleActions}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
