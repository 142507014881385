import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Product } from "../interfaces/AllProducts.interface";
import { ManualOrderProductFormData } from "../interfaces/CreateProduct.interface";
import { RootState } from "../redux/store";
import {
  createManualOrder,
  getAllDistricts,
  getAllProducts,
  getSearchProducts,
  getUpazilaByDistrict,
} from "../services/axios.services";

export default function ManualOrder() {
  const token = useSelector((state: RootState) => state.user.token);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchProducts, setSearchProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [deliveryCharge, setDeliveryCharge] = useState<number>(0);
  const [advanceAmount, setAdvanceAmount] = useState<number>(0);
  const [additionalDiscount, setAdditionalDiscount] = useState<number>(0);
  const [allDistricts, setAllDistricts] = useState<any[]>([]);
  // const [selectedDistrict, setSelectedDistrict] = useState("");
  const [allUpazila, setAllUpazila] = useState<any[]>([]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ManualOrderProductFormData>({});

  const selectedDistrict = watch("addressInfo.district");

  const fetchProducts = async () => {
    const response = await getAllProducts(1, 10, token as string);
    setSearchProducts(response.data.books);
  };

  const fetchSearchProducts = async (value: string) => {
    const response = await getSearchProducts(
      currentPage,
      10,
      token as string,
      value
    );
    setSearchProducts(response.data.books);
  };

  const fetchDistricts = async () => {
    const response = await getAllDistricts(token as string);
    console.log("dis -> ", response);
    setAllDistricts(response);
  };

  const fetchUpazila = async (districtId: string) => {
    const response = await getUpazilaByDistrict(districtId, token as string);
    console.log("upa -> ", response);
    setAllUpazila(response);
  };

  useEffect(() => {
    fetchDistricts();
  }, []);

  useEffect(() => {
    if (selectedDistrict) {
      // Fetch upazilas whenever the district changes
      fetchUpazila(selectedDistrict);
      const found = allDistricts.find((item) => item.id == selectedDistrict);
      console.log("found -> ", found);
      if (found.name == "Dhaka") setDeliveryCharge(60);
      else setDeliveryCharge(120);
      // setAllUpazila(response);
    } else {
      setAllUpazila([]); // Clear upazilas if no district is selected
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (searchValue == "") fetchProducts();
    else fetchSearchProducts(searchValue);
  }, [searchValue]);

  useEffect(() => {
    let newPrice = 0;
    selectedProducts.forEach(
      (item) => (newPrice += item.afterDiscountPrice * item.orderedQuantity!)
    );
    setPrice(newPrice);
    setSubtotal(newPrice);
  }, [selectedProducts, price, subtotal]);

  const handleAddProduct = (product: Product) => {
    const newProduct = [...selectedProducts];
    const found = newProduct.find((item) => item._id == product._id);
    if (found) {
      toast.error("Product already added");
      return;
    }
    product.orderedQuantity = 1;
    setSelectedProducts([...newProduct, product]);
    toast.success("Product added successfully");
  };

  const handleQuantity = (product: Product, action: string) => {
    const newProduct = [...selectedProducts];
    const index = newProduct.findIndex((item) => item._id === product._id);

    if (index === -1) return;

    const currentProduct = newProduct[index];

    if (currentProduct.orderedQuantity === undefined) {
      return;
    }

    if (action === "add") {
      currentProduct.orderedQuantity++;
    } else if (action === "sub") {
      if (currentProduct.orderedQuantity <= 1) return;
      currentProduct.orderedQuantity--;
    }
    setSelectedProducts(newProduct);
  };

  const handleRemove = (product: Product) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((p) => p._id !== product._id)
    );
  };

  const onSubmit = async (data: ManualOrderProductFormData) => {
    const reformedProductArray: any[] = [];
    selectedProducts.forEach((item) =>
      reformedProductArray.push({
        productId: item?._id,
        quantity: item?.orderedQuantity!,
        perPrice: item?.afterDiscountPrice,
        totalPrice: item?.afterDiscountPrice * item?.orderedQuantity!,
      })
    );
    const districtName = allDistricts.find(
      (district) => district.id == data.addressInfo.district
    );
    const response = await createManualOrder(
      {
        customer: {
          isRegisterCustomer: false,
          customerId: null,
          name: data.customer.name,
          phone: data.customer.phone,
        },
        addressInfo: {
          district: districtName.name,
          upazila: data.addressInfo.upazila,
          details: data.addressInfo.details,
        },
        deliveryCharge,
        discountType: "flat",
        discountPrice: additionalDiscount,
        totalProductPrice: price,
        afterDiscountTotalProductPrice: price,
        payablePrice: price + deliveryCharge - additionalDiscount,
        advancePrice: advanceAmount,
        duePrice: price + deliveryCharge - (advanceAmount + additionalDiscount),
        paymentStatus: "unpaid",
        paymentType: "cash on delivery",
        orderSource: data.orderSource,
        products: reformedProductArray,
      },
      token as string
    );
    console.log("response -> ", response);
    if (response?.data.success) {
      reset();
      setAdditionalDiscount(0);
      setPrice(0);
      setSubtotal(0);
      setAdvanceAmount(0);
      setDeliveryCharge(0);
      toast.success(response.data.message);
    }
  };

  return (
    <Box pt={5}>
      <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select products</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder="Search product by name, sku, author"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchProducts?.map((item) => (
              <Box p={3}>
                <Flex gap={3} justifyContent={"space-between"}>
                  <Flex gap={3}>
                    <Box w={"50px"}>
                      <Image
                        src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`}
                        alt="Preview"
                        maxW="50px"
                        maxH="60px"
                      />
                    </Box>
                    <Box>
                      <Text>{item?.nameBangla}</Text>
                      <Text>{item?.afterDiscountPrice}</Text>
                    </Box>
                  </Flex>
                  <Button
                    color={"white"}
                    colorScheme="blue"
                    onClick={() => handleAddProduct(item)}
                  >
                    Add
                  </Button>
                </Flex>
              </Box>
            ))}
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={"1fr 1fr"} gap={2}>
          <GridItem>
            <Box
              mx={5}
              my={5}
              border={"1px solid hsl(0, 0%, 92%)"}
              borderRadius={5}
              px={8}
              pt={5}
              pb={8}
            >
              <Heading size={"md"} mb={2}>
                Customer Information
              </Heading>
              <Divider />
              <Grid templateColumns={"1fr 1fr"} gap={5}>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Customer Name
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Type customer name"
                    {...register("customer.name", {
                      required: "Customer name is required",
                    })}
                  />
                  {errors.customer?.name && (
                    <Text color={"tomato"}>{errors.customer.name.message}</Text>
                  )}
                </GridItem>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Phone
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Type customer phone"
                    {...register("customer.phone", {
                      required: "Phone is required.",
                    })}
                  />
                  {errors.customer?.phone && (
                    <Text color={"tomato"}>
                      {errors.customer.phone.message}
                    </Text>
                  )}
                </GridItem>
                <GridItem colSpan={2}>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Order source
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Select
                    // onChange={(e) => setSelectedDistrict(e.target.value)}
                    placeholder="Select Order Source"
                    {...register("orderSource", {
                      required: "Order source is required",
                    })}
                  >
                    <option value={"facebook"}>Facebook</option>
                    <option value={"whats app"}>Whats App</option>
                    <option value={"instagram"}>Instagram</option>
                    <option value={"phone call"}>Phone Call</option>
                    <option value={"others"}>Others</option>
                  </Select>
                  {errors.orderSource && (
                    <Text color={"tomato"}>{errors.orderSource.message}</Text>
                  )}
                </GridItem>
              </Grid>
            </Box>
            <Box
              mx={5}
              border={"1px solid hsl(0, 0%, 92%)"}
              borderRadius={5}
              px={8}
              pt={5}
              pb={8}
            >
              <Heading size={"md"} mb={2}>
                Delivery Information
              </Heading>
              <Divider />
              <Grid templateColumns={"1fr 1fr"} gap={5}>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Select District (city)
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Select
                    // onChange={(e) => setSelectedDistrict(e.target.value)}
                    placeholder="Select District"
                    {...register("addressInfo.district", {
                      required: "District is required",
                    })}
                  >
                    {allDistricts?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </Select>
                  {errors.addressInfo?.district && (
                    <Text color={"tomato"}>
                      {errors.addressInfo?.district.message}
                    </Text>
                  )}
                </GridItem>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Select Thana (zone)
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Select
                    placeholder="Select Thana"
                    {...register("addressInfo.upazila", {
                      required: "Upazila is required.",
                    })}
                  >
                    {allUpazila?.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </Select>
                  {errors.addressInfo?.upazila && (
                    <Text color={"tomato"}>
                      {errors.addressInfo.upazila.message}
                    </Text>
                  )}
                </GridItem>
                <GridItem colSpan={2}>
                  <FormLabel fontSize={"14px"}>
                    Address
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Textarea
                    placeholder="Type details mailing address"
                    {...register("addressInfo.details", {
                      required: "Details Address is required.",
                    })}
                  />
                  {errors.addressInfo?.details && (
                    <Text color={"tomato"}>
                      {errors.addressInfo?.details.message}
                    </Text>
                  )}
                </GridItem>
              </Grid>
            </Box>
            <Box
              mx={5}
              my={5}
              border={"1px solid hsl(0, 0%, 92%)"}
              borderRadius={5}
              px={5}
              pt={5}
              pb={8}
            >
              <Heading mb={3} as={"h2"} size={"sm"}>
                Total Pricing Calculation
              </Heading>
              <Divider />
              <Flex justify={"space-between"} mt={5} px={3}>
                <Text>Price (MRP)</Text>
                <Text>{price} Tk</Text>
              </Flex>
              <Flex justify={"space-between"} mt={3} px={3}>
                <Text>Subtotal</Text>
                <Text>{subtotal} Tk</Text>
              </Flex>
              <Flex justify={"space-between"} mt={3} px={3} mb={5}>
                <Text>Delivery Cost</Text>
                <Text>{deliveryCharge} Tk</Text>
              </Flex>
              <Divider />
              <Flex justify={"space-between"} mt={3} px={3} mb={5}>
                <Text>Advanced</Text>
                <Text>{advanceAmount} Tk</Text>
              </Flex>
              <Flex justify={"space-between"} mt={3} px={3} mb={5}>
                <Text my={"auto"}>Additional Discount</Text>
                <Input
                  type="number"
                  w={"150px"}
                  size={"sm"}
                  placeholder="Discount amount"
                  onChange={(e) =>
                    setAdditionalDiscount(Number(e.target.value))
                  }
                />
                <Text my={"auto"}>{additionalDiscount} Tk</Text>
              </Flex>
              <Divider />
              <Flex justify={"space-between"} mt={3} px={3}>
                <Text>Due</Text>
                <Text>
                  {price +
                    deliveryCharge -
                    (advanceAmount + additionalDiscount)}{" "}
                  Tk
                </Text>
              </Flex>
            </Box>
          </GridItem>
          <GridItem>
            <Box
              mx={5}
              my={5}
              border={"1px solid hsl(0, 0%, 92%)"}
              borderRadius={5}
              px={8}
              pt={5}
              pb={8}
            >
              <Heading size={"md"} mb={2}>
                Payment methods & others
              </Heading>
              <Divider />
              <Grid templateColumns={"1fr 1fr"} gap={5}>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"} mb={3.5}>
                    Payment Method
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Select
                    placeholder="Select Payment Method"
                    {...register("paymentType", {
                      required: "Payment type is required.",
                    })}
                  >
                    <option value="cash on delivery">Cash on delivery</option>
                  </Select>
                </GridItem>
                <GridItem>
                  <FormLabel mt={3} fontSize={"14px"}>
                    Advance Amount
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </FormLabel>
                  <Input
                    type="number"
                    placeholder="Type Advance Amount"
                    onChange={(e) => setAdvanceAmount(Number(e.target.value))}
                  />
                </GridItem>
              </Grid>
            </Box>
            <Box
              mx={5}
              my={5}
              border={"1px solid hsl(0, 0%, 92%)"}
              borderRadius={5}
              px={8}
              pt={5}
              pb={8}
            >
              <Heading mb={3} as={"h2"} size={"md"}>
                Products
              </Heading>
              <Divider />
              {selectedProducts?.map((item) => (
                <Box mt={2}>
                  <Flex gap={2} justifyContent={"space-between"}>
                    <Flex gap={2}>
                      <Image
                        alt="Preview"
                        maxW="50px"
                        maxH="60px"
                        src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`}
                      />
                      <Box>
                        <Text>{item?.nameBangla}</Text>
                        <Text
                          as={
                            item?.salesPrice !== item?.afterDiscountPrice
                              ? "del"
                              : "samp"
                          }
                          color={
                            item?.salesPrice !== item?.afterDiscountPrice
                              ? "grey"
                              : "black"
                          }
                        >
                          {item?.salesPrice}
                        </Text>
                        {item?.salesPrice !== item?.afterDiscountPrice ? (
                          <Text>{item?.afterDiscountPrice}</Text>
                        ) : null}
                      </Box>
                    </Flex>
                    <Box>
                      <Button
                        mr={3}
                        onClick={() => handleQuantity(item, "add")}
                      >
                        +
                      </Button>
                      {item?.orderedQuantity}
                      <Button
                        ml={3}
                        onClick={() => handleQuantity(item, "sub")}
                      >
                        -
                      </Button>
                      <Button ml={3} onClick={() => handleRemove(item)}>
                        <MdDeleteForever />
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              ))}
              <Button
                mt={5}
                color={"white"}
                colorScheme="blue"
                onClick={onOpen}
              >
                Add Product
              </Button>
            </Box>
          </GridItem>
        </Grid>
        <Box textAlign={"center"} mt={10} mb={20}>
          <Button color={"white"} colorScheme="blue" px={10} type="submit">
            Place Order
          </Button>
        </Box>
      </form>
    </Box>
  );
}
