import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Author } from "../interfaces/Author.interface";
import { RootState } from "../redux/store";
import {
  approveOrRejectAuthorOrTranslator,
  getUnapprovedAuthorOrTranslator,
} from "../services/axios.services";

export default function PendingAuthors() {
  const token = useSelector((state: RootState) => state.user.token);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allAuthors, setAllAuthors] = useState<Author[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [typeOfAction, setTypeOfAction] = useState<string>("");

  const fetchAuthors = async () => {
    const authors = await getUnapprovedAuthorOrTranslator(
      "authors",
      token as string
    );
    console.log("all authors -> ", authors.data);
    setAllAuthors(authors.data);
  };

  useEffect(() => {
    fetchAuthors();
  }, []);

  // Type the columns array with your Product type
  const columns: ColumnDef<Author, any>[] = [
    {
      header: "Image",
      cell: ({ row }) => (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${row.original.profilePicture}`}
          alt="Product Image"
          width={50}
          height={50}
        />
      ),
    },
    {
      header: "Name",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.nameBangla}</Text>
          <Text>{row.original.nameEnglish}</Text>
        </Box>
      ),
    },
    {
      header: "Email & Phone",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.email}</Text>
          <Text>{row.original.phone}</Text>
        </Box>
      ),
    },
    {
      header: "NID",
      accessorKey: "nidNumber",
    },
    {
      header: "Permanent Address",
      accessorKey: "permanentAddress",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Flex gap={2} justifyContent={"center"}>
          <IconButton
            aria-label="View Product"
            icon={<AiOutlineCheck />}
            color={"green"}
            colorScheme="white"
            border={"1px solid green"}
            onClick={() => {
              onOpen();
              setSelectedId(row?.original?._id ?? "");
              setTypeOfAction("Approve");
            }}
          />
          <IconButton
            aria-label="View Product"
            icon={<AiOutlineClose />}
            color={"red"}
            colorScheme="white"
            border={"1px solid red"}
            onClick={() => {
              onOpen();
              setSelectedId(row?.original?._id ?? "");
              setTypeOfAction("Reject");
            }}
          />
        </Flex>
      ),
    },
  ];

  const table = useReactTable({
    data: allAuthors,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const handleActions = async () => {
    const reqBody = {
      id: selectedId,
      value: typeOfAction == "Reject" ? "remove" : "approved",
    };
    const response = await approveOrRejectAuthorOrTranslator(
      reqBody,
      "authors",
      token as string
    );
    if (response?.success) {
      toast.success(response.message);
      fetchAuthors();
      onClose();
    }
  };

  return (
    <Box>
      <Heading as={"h3"} size={"lg"} my={5} ml={5}>
        Pending Authors List
      </Heading>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{typeOfAction} Author</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure?</ModalBody>

          <ModalFooter gap={3}>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleActions}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
