import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../redux/features/userSlice";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Add your logout logic here
    dispatch(setUserData({ email: "", token: "" }));
    navigate("/login");
  };

  return (
    <Flex as="nav" justify="flex-end" align="center" bg="gray.100" p={4}>
      <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <Box display="flex" alignItems="center">
          <Avatar name="Admin" src="https://bit.ly/broken-link" />
          <Text ml={2}>Admin</Text>
        </Box>
        <MenuButton as={IconButton} variant="outline" icon={<FaAngleDown />} />
        <MenuList>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Navbar;
