import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { bindings } from "../constants/bindings";
import { languages } from "../constants/languages";
import { Product } from "../interfaces/AllProducts.interface";
import { Author } from "../interfaces/Author.interface";
import {
  ProductFormData,
  Translator,
} from "../interfaces/CreateProduct.interface";
import { RootState } from "../redux/store";
import {
  getAllActiveCategory,
  getAuthorList,
  getSingleProduct,
  getTranslatorList,
  updateProduct,
} from "../services/axios.services";

const EditProduct: React.FC = () => {
  const token = useSelector((state: RootState) => state.user.token);
  const { id } = useParams();
  const navigate = useNavigate();

  const [productDetails, setProductDetails] = useState<Product>();
  const [coverImage, setCoverImage] = useState("");
  const [shortPdf, setShortPdf] = useState("");
  const [ebookPdf, setEbookPdf] = useState("");
  const [ebookPrice, setEbookPrice] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [discountType, setDiscountType] = useState<string | null>(null);
  const [discountAmount, setDiscountAmount] = useState<number | null>(0);
  const [buyingPrice, setBuyingPrice] = useState<string>("");
  const [allAuthors, setAllAuthors] = useState<any[]>([]);
  const [allTranlators, setAllTranlators] = useState<any[]>([]);
  const [allCategories, setAllCategories] = useState<any[]>();
  const [ebookSell, setEbookSell] = useState<string>("hardbook");
  const [purchasedPrice, setPurchasedPrice] = useState<number | null>(null);

  const fetchProductDetails = async () => {
    const response = await getSingleProduct(id!, token as string);
    console.log("respn -> ", response);
    if (response.success) {
      setProductDetails(response.data);
      const newAutor: any[] = [];
      response.data.authors.forEach((author: Author) => {
        newAutor.push({
          id: author._id,
          nameBangla: author.nameBangla,
          commissionType: author.commissionType,
          commissionAmount: author.commissionAmount,
        });
      });

      const newTranslator: any[] = [];
      response.data.translators.forEach((translator: Author) => {
        newTranslator.push({
          id: translator._id,
          nameBangla: translator.nameBangla,
          commissionType: translator.commissionType,
          commissionAmount: translator.commissionAmount,
        });
      });

      setBuyingPrice(response.data.afterDiscountPrice);
      setEbookSell(
        response.data.eBook
          ? response.data.eBookInfo.bothSell
            ? "both"
            : "ebook"
          : "hardbook"
      );
      setCoverImage(`${response.data.image}`);
      setShortPdf(response.data.shortPdf);
      setEbookPrice(response.data.eBook ? response.data.eBookInfo.price : null);
      setEbookPdf(response.data.eBook ? `${response.data.eBookInfo.pdf}` : "");
      reset({
        nameBangla: response.data.nameBangla,
        nameEnglish: response.data.nameEnglish,
        subTitle: response.data.subTitle,
        language: response.data.language,
        quantity: response.data.quantity,
        authors: newAutor,
        translators: response.data?.translators?.length
          ? newTranslator
          : [{} as Translator],
        binding: response.data.binding,
        supplier: response.data.supplier,
        isbn: response.data.isbn,
        edition: response.data.edition,
        numberOfPage: response.data.numberOfPage,
        summary: response.data.summary,
        categoryId: response.data.category._id,
        orderType: response.data.orderType,
        purchasePrice: response.data.purchasePrice,
        price: response.data.salesPrice,
        priceDiscountType: response.data.salesPriceDiscountType,
        resellerCommissionType:
          response.data.partyWiseCommissions[0].commissionType,
        resellerCommissionAmount:
          response.data.partyWiseCommissions[0].commissionAmount,
        agentCommissionType:
          response.data.partyWiseCommissions[1].commissionType,
        agentCommissionAmount:
          response.data.partyWiseCommissions[1].commissionAmount,
        distributorCommissionType:
          response.data.partyWiseCommissions[2].commissionType,
        distributorCommissionAmount:
          response.data.partyWiseCommissions[2].commissionAmount,
        priceDiscountAmount: response.data.salesPriceDiscountAmount,
      });
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ProductFormData>({});

  const {
    fields: authorFields,
    append: appendAuthor,
    remove: removeAuthor,
  } = useFieldArray({
    control,
    name: "authors",
  });

  const {
    fields: translatorFields,
    append: appendTranslator,
    remove: removeTranslator,
  } = useFieldArray({
    control,
    name: "translators",
  });

  useEffect(() => {
    const fetchAuthors = async () => {
      const authors = await getAuthorList(token as string);
      setAllAuthors(authors);
    };

    const fetchTranslators = async () => {
      const authors = await getTranslatorList(token as string);
      setAllTranlators(authors);
    };

    const fetchActiveCategories = async () => {
      const categories = await getAllActiveCategory(token as string);
      setAllCategories(categories.data);
    };

    fetchAuthors();
    fetchTranslators();
    fetchActiveCategories();
  }, [token]);

  // console.log("all authors -> ", allAuthors);

  useEffect(() => {
    if (price && discountType === "percentage") {
      const discountedPrice = price - (price * discountAmount!) / 100;
      setBuyingPrice(discountedPrice.toString());
    } else if (price && discountType === "flat") {
      setBuyingPrice((price - discountAmount!).toString());
    }
  }, [price, discountType, discountAmount, buyingPrice]);

  const convertCoverImageToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setCoverImage(reader.result as string);
    };
  };

  const convertPdfToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setShortPdf(reader.result as string);
    };
  };

  const convertEbookToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEbookPdf(reader.result as string);
    };
  };

  const handlePdf = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      convertPdfToBase64(file);
    }
  };

  const handleEbook = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      convertEbookToBase64(file);
    }
  };

  const handleCoverImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      convertCoverImageToBase64(file);
    }
  };

  const onSubmit = async (data: ProductFormData) => {
    if (!coverImage) {
      toast("Please add a cover image");
      return;
    }
    if ((ebookPdf && !ebookPrice) || (ebookPrice && !ebookPdf)) {
      toast("Please add both eBook and eBook price or vice versa");
      return;
    }
    if (purchasedPrice! > price! || purchasedPrice! > Number(buyingPrice)) {
      toast.warning(
        "Purchase Price must be greater than selling price or discounted price"
      );
      return;
    }
    let requestAuthor = [];
    for (let item of allAuthors) {
      for (let filterItem of data.authors) {
        if (item._id === filterItem.id) {
          const newItem = {
            authorId: item._id,
            slug: item.slug,
            nameBangla: item.nameBangla,
            nameEnglish: item.nameEnglish,
            commissionType: filterItem.commissionType,
            commissionAmount: filterItem.commissionAmount,
          };
          requestAuthor.push(newItem);
          break;
        }
      }
    }
    let requestTranslator: any[] = [];
    for (let item of allTranlators) {
      for (let filterItem of data.translators) {
        if (item._id === filterItem.id) {
          const newItem = {
            translatorId: item._id,
            slug: item.slug,
            nameBangla: item.nameBangla,
            nameEnglish: item.nameEnglish,
            commissionType: filterItem.commissionType,
            commissionAmount: filterItem.commissionAmount,
          };
          requestTranslator.push(newItem);
          break;
        }
      }
    }
    const requestBody: any = {
      id: id,
      nameBangla: data.nameBangla,
      nameEnglish: data.nameEnglish,
      subTitle: data.subTitle,
      authors: requestAuthor,
      translators: requestTranslator,
      language: data.language,
      binding: data.binding,
      supplier: data.supplier,
      quantity: data.quantity,
      purchasePrice: data.purchasePrice,
      salesPrice: data.price,
      salesPriceDiscountType: data.priceDiscountType,
      salesPriceDiscountAmount: data.priceDiscountAmount,
      afterDiscountPrice: buyingPrice,
      edition: data.edition,
      isbn: data.isbn,
      orderType: data.orderType,
      numberOfPage: data.numberOfPage,
      image: coverImage,
      shortPdf: shortPdf,
      summary: data.summary,
      categoryId: data.categoryId,
      partyWiseCommissions: [
        {
          type: "reseller",
          commissionType: data.resellerCommissionType,
          commissionAmount: data.resellerCommissionAmount,
        },
        {
          type: "agent",
          commissionType: data.agentCommissionType,
          commissionAmount: data.agentCommissionAmount,
        },
        {
          type: "distributor",
          commissionType: data.distributorCommissionType,
          commissionAmount: data.distributorCommissionAmount,
        },
      ],
    };
    if (ebookPdf !== "") {
      requestBody.eBookInfo = {
        eBookSell: ebookSell == "ebook" || ebookSell == "both" ? true : false,
        hardBookSell:
          ebookSell == "hardbook" || ebookSell == "both" ? true : false,
        bothSell: ebookSell == "both" ? true : false,
        price: ebookPrice,
        pdf: ebookPdf,
      };
    }
    const response = await updateProduct(requestBody, token as string);
    if (response?.data.success) {
      toast.success(response.data.message);
      setEbookPdf("");
      setCoverImage("");
      setShortPdf("");
      reset();
      navigate("/all-products");
    } else toast.error(response?.data.message);
  };

  return (
    <Box p={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
          <GridItem colSpan={3}>
            <Heading as="h4" size="md">
              Product Info
            </Heading>
          </GridItem>
          <GridItem>
            <FormLabel>
              Product Name (Bangla)
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("nameBangla", {
                required: "Bangla name is required",
                maxLength: {
                  value: 80,
                  message: "Maximum length is 80 characters",
                },
              })}
            />
            {errors.nameBangla && (
              <Text color={"tomato"}>{errors.nameBangla.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>
              Product Name (English)
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Input
              type="text"
              {...register("nameEnglish", {
                required: "English name is required",
                maxLength: {
                  value: 80,
                  message: "Maximum length is 80 characters",
                },
              })}
            />
            {errors.nameEnglish && (
              <Text color={"tomato"}>{errors.nameEnglish.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>Subtitle</FormLabel>
            <Input
              type="text"
              {...register("subTitle", {
                maxLength: {
                  value: 180,
                  message: "Maximum length is 180 characters",
                },
              })}
            />
            {errors.subTitle && (
              <Text color={"tomato"}>{errors.subTitle.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel mb={3.5}>
              Language
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Select
              placeholder="Select language"
              {...register("language", {
                required: "Language is required",
              })}
            >
              {languages.map((item) => (
                <option value={item?.name}>{item?.name}</option>
              ))}
            </Select>
            {errors.language && (
              <Text color={"tomato"}>{errors.language.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel mb={3.5}>
              Binding
              <Text as="span" color={"red.400"}>
                *
              </Text>
            </FormLabel>
            <Select
              placeholder="Select binding"
              {...register("binding", {
                required: "Binding is required",
              })}
            >
              {bindings.map((item) => (
                <option value={item?.name}>{item?.name}</option>
              ))}
            </Select>
            {errors.binding && (
              <Text color={"tomato"}>{errors.binding.message}</Text>
            )}
          </GridItem>
          <GridItem>
            <FormLabel>Supplier</FormLabel>
            <Input type="text" {...register("supplier")} />
          </GridItem>
          <GridItem colSpan={3}>
            <Divider mt={5} />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading as="h4" size="md">
              Authors Info
            </Heading>
          </GridItem>
          {authorFields.map((author, index) => (
            <React.Fragment key={author.id}>
              <GridItem>
                <FormLabel mb={3.5}>
                  Author Name (Bangla)
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Author"
                  {...register(`authors.${index}.id`, {
                    required: "Author name is required",
                  })}
                >
                  {allAuthors?.map((item) => (
                    <option value={item._id}>{item?.nameBangla}</option>
                  ))}
                </Select>
                {errors.authors?.[index]?.id && (
                  <Text color={"tomato"}>
                    {errors.authors[index]?.id?.message}
                  </Text>
                )}
              </GridItem>
              <GridItem colSpan={2}>
                <Grid templateColumns="1fr 1fr 1fr" gap={5}>
                  <GridItem>
                    <FormLabel mb={3.5}>
                      Commission Type
                      <Text as="span" color={"red.400"}>
                        *
                      </Text>
                    </FormLabel>
                    <Select
                      placeholder="Select Commission Type"
                      {...register(`authors.${index}.commissionType`, {
                        required: "Commission Type is required",
                      })}
                    >
                      <option value="flat">Flat</option>
                      <option value="percentage">Percentage</option>
                    </Select>
                    {errors.authors?.[index]?.commissionType && (
                      <Text color={"tomato"}>
                        {errors.authors[index]?.commissionType?.message}
                      </Text>
                    )}
                  </GridItem>
                  <GridItem>
                    <FormLabel>
                      Commission Amount
                      <Text as="span" color={"red.400"}>
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      type="text"
                      {...register(`authors.${index}.commissionAmount`, {
                        required: "Commission Amount is required",
                      })}
                    />
                    {errors.authors?.[index]?.commissionAmount && (
                      <Text color={"tomato"}>
                        {errors.authors[index]?.commissionAmount?.message}
                      </Text>
                    )}
                  </GridItem>
                  <GridItem>
                    <Grid templateColumns={"1fr 1fr"} gap={5}>
                      {authorFields?.length == index + 1 ? (
                        <GridItem>
                          <Button
                            color={"white"}
                            colorScheme="green"
                            w={"full"}
                            mt={9}
                            onClick={() =>
                              appendAuthor({
                                id: "",
                                authorId: "",
                                slug: "",
                                nameBangla: "",
                                nameEnglish: "",
                                commissionType: "",
                                commissionAmount: 0,
                              })
                            }
                          >
                            Add
                          </Button>
                        </GridItem>
                      ) : null}
                      {authorFields.length == 1 ? null : (
                        <GridItem
                          colSpan={authorFields.length == index + 1 ? 1 : 2}
                        >
                          <Button
                            w={"full"}
                            type="submit"
                            colorScheme="red"
                            color={"white"}
                            mt={9}
                            onClick={() => removeAuthor(index)}
                          >
                            Remove
                          </Button>
                        </GridItem>
                      )}
                    </Grid>
                  </GridItem>
                </Grid>
              </GridItem>
            </React.Fragment>
          ))}
          <GridItem colSpan={3}>
            <Divider mt={5} />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading as="h4" size="md">
              Translators Info (if any)
            </Heading>
          </GridItem>
          {translatorFields.map((translator, index) => (
            <React.Fragment key={translator.id}>
              <GridItem>
                <FormLabel mb={3.5}>Translator Name (Bangla)</FormLabel>
                <Select
                  placeholder="Select Translator"
                  {...register(`translators.${index}.id`)}
                >
                  {allTranlators?.map((item) => (
                    <option value={item?._id}>{item?.nameBangla}</option>
                  ))}
                </Select>
              </GridItem>
              <GridItem colSpan={2}>
                <Grid templateColumns="1fr 1fr 1fr" gap={5}>
                  <GridItem>
                    <FormLabel mb={3.5}>Commission Type</FormLabel>
                    <Select
                      placeholder="Select Commission Type"
                      {...register(`translators.${index}.commissionType`)}
                    >
                      <option value="flat">Flat</option>
                      <option value="percentage">Percentage</option>
                    </Select>
                  </GridItem>
                  <GridItem>
                    <FormLabel>Commission Amount</FormLabel>
                    <Input
                      type="text"
                      {...register(`translators.${index}.commissionAmount`)}
                    />
                  </GridItem>
                  <GridItem>
                    <Grid templateColumns={"1fr 1fr"} gap={4}>
                      {translatorFields?.length == index + 1 ? (
                        <GridItem>
                          <Button
                            w={"full"}
                            mt={9}
                            color={"white"}
                            colorScheme="green"
                            onClick={() =>
                              appendTranslator({
                                id: "",
                                translatorId: "",
                                slug: "",
                                nameBangla: "",
                                nameEnglish: "",
                                commissionType: "",
                                commissionAmount: 0,
                              })
                            }
                          >
                            Add
                          </Button>
                        </GridItem>
                      ) : null}
                      {translatorFields?.length == 1 ? null : (
                        <GridItem
                          colSpan={
                            translatorFields?.length == index + 1 ? 1 : 2
                          }
                        >
                          <Button
                            w={"full"}
                            type="submit"
                            colorScheme="red"
                            color={"white"}
                            mt={9}
                            onClick={() => removeTranslator(index)}
                          >
                            Remove
                          </Button>
                        </GridItem>
                      )}
                    </Grid>
                  </GridItem>
                </Grid>
              </GridItem>
            </React.Fragment>
          ))}
          <GridItem colSpan={3}>
            <Divider mt={5} />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading as="h4" size="md">
              Other Info
            </Heading>
          </GridItem>
          <GridItem colSpan={3}>
            <Grid templateColumns={"repeat(6, 1fr)"} gap={5}>
              <GridItem>
                <FormLabel>
                  Stock
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("quantity", {
                    required: "Quantity is required",
                    min: { value: 1, message: "Quantity must be at least 1" },
                  })}
                />
                {errors.quantity && (
                  <Text color={"tomato"}>{errors.quantity.message}</Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Purchase Price
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("purchasePrice", {
                    required: "Purchase Price is required",
                    min: {
                      value: 1,
                      message: "Purchase Price must be at least 1",
                    },
                  })}
                  onChange={(e) => setPurchasedPrice(Number(e.target.value))}
                />
                {!purchasedPrice && errors.purchasePrice && (
                  <Text color={"tomato"}>{errors.purchasePrice.message}</Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Selling Price
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("price", {
                    required: "Selling Price is required",
                    min: {
                      value: 1,
                      message: "Selling Price must be at least 1",
                    },
                  })}
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
                {!price && errors.price && (
                  <Text color={"tomato"}>{errors.price.message}</Text>
                )}
                {price! < purchasedPrice! && (
                  <Text color={"tomato"}>
                    Must be greater than purchase price
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel mb={3}>
                  Discount Type
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Discount Type"
                  {...register("priceDiscountType", {
                    required: "Discount Type is required",
                    min: { value: 1, message: "Price must be at least 1" },
                  })}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </Select>
                {!discountType && errors.priceDiscountType && (
                  <Text color={"tomato"}>
                    {errors.priceDiscountType.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Discount Amount
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("priceDiscountAmount", {
                    required: "Discount Amount is required",
                    min: { value: 1, message: "Price must be at least 1" },
                  })}
                  onChange={(e) => setDiscountAmount(Number(e.target.value))}
                />
                {!discountAmount && errors.priceDiscountAmount && (
                  <Text color={"tomato"}>
                    {errors.priceDiscountAmount.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Discounted Price
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input type="text" value={buyingPrice} />
                {Number(buyingPrice) < purchasedPrice! && (
                  <Text color={"tomato"}>
                    Must be greater than purchase price
                  </Text>
                )}
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={3}>
            <Grid templateColumns={"repeat(10, 1fr)"} gap={5}>
              <GridItem colSpan={2}>
                <FormLabel>ISBN</FormLabel>
                <Input type="number" {...register("isbn")} />
              </GridItem>
              <GridItem colSpan={1}>
                <FormLabel>
                  Edition
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="text"
                  {...register("edition", {
                    required: "Edition is required",
                    min: { value: 1, message: "Price must be at least 1" },
                  })}
                />
                {errors.edition && (
                  <Text color={"tomato"}>{errors.edition.message}</Text>
                )}
              </GridItem>
              <GridItem colSpan={2}>
                <FormLabel mb={3}>
                  Order Type
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Order Type"
                  {...register("orderType", {
                    required: "Order type is required",
                  })}
                >
                  <option value="buynow">Buy Now</option>
                  <option value="preorder">Pre order</option>
                </Select>
                {errors.orderType && (
                  <Text color={"tomato"}>{errors.orderType.message}</Text>
                )}
              </GridItem>
              <GridItem colSpan={2}>
                <FormLabel>
                  Number of Pages
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("numberOfPage", {
                    required: "Number of pages is required",
                    min: { value: 1, message: "Price must be at least 1" },
                  })}
                />
                {errors.numberOfPage && (
                  <Text color={"tomato"}>{errors.numberOfPage.message}</Text>
                )}
              </GridItem>
              <GridItem colSpan={3}>
                <FormLabel mb={3}>
                  Category
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Category"
                  {...register("categoryId", {
                    required: "Category is required",
                  })}
                >
                  {allCategories?.map((item) => (
                    <option value={item?._id}>{item?.nameBangla}</option>
                  ))}
                </Select>
                {errors.categoryId && (
                  <Text color={"tomato"}>{errors.categoryId.message}</Text>
                )}
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={3}>
            <Divider mt={5} mb={5} />
          </GridItem>
          <GridItem colSpan={3}>
            <Heading as="h4" size="md">
              Commission Info
            </Heading>
          </GridItem>
          <GridItem colSpan={3}>
            <Grid templateColumns={"repeat(2, 1fr)"} gap={5}>
              <GridItem>
                <FormLabel mb={3}>
                  Reseller Comm. Type
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Commission Type"
                  {...register("resellerCommissionType", {
                    required: "This field is required",
                  })}
                >
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </Select>
                {errors.resellerCommissionType && (
                  <Text color={"tomato"}>
                    {errors.resellerCommissionType.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Reseller Comm. Amount
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("resellerCommissionAmount", {
                    required: "This field is required",
                    min: { value: 1, message: "Must be at least 1" },
                  })}
                />
                {errors.resellerCommissionAmount && (
                  <Text color={"tomato"}>
                    {errors.resellerCommissionAmount.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel mb={3}>
                  Agent Comm. Type
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Commission Type"
                  {...register("agentCommissionType", {
                    required: "This field is required",
                  })}
                >
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </Select>
                {errors.agentCommissionType && (
                  <Text color={"tomato"}>
                    {errors.agentCommissionType.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Agent Comm. Amount
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("agentCommissionAmount", {
                    required: "This field is required",
                    min: { value: 1, message: "Must be at least 1" },
                  })}
                />
                {errors.agentCommissionAmount && (
                  <Text color={"tomato"}>
                    {errors.agentCommissionAmount.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel mb={3}>
                  Distributor Comm. Type
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  placeholder="Select Commission Type"
                  {...register("distributorCommissionType", {
                    required: "This field is required",
                  })}
                >
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </Select>
                {errors.distributorCommissionType && (
                  <Text color={"tomato"}>
                    {errors.distributorCommissionType.message}
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>
                  Distributor Comm. Amount
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="number"
                  {...register("distributorCommissionAmount", {
                    required: "This field is required",
                    min: { value: 1, message: "Must be at least 1" },
                  })}
                />
                {errors.distributorCommissionAmount && (
                  <Text color={"tomato"}>
                    {errors.distributorCommissionAmount.message}
                  </Text>
                )}
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={3}>
            <Divider mt={5} mb={5} />
          </GridItem>
          <GridItem colSpan={3} mb={5}>
            <RadioGroup onChange={setEbookSell} value={ebookSell}>
              <Stack direction="row" gap={8}>
                <Radio value="ebook">Ebook Sell Only</Radio>
                <Radio value="hardbook">Hard Book Sell Only</Radio>
                <Radio value="both">Both Sell</Radio>
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem colSpan={3}>
            <Grid
              templateColumns={"repeat(6, 1fr)"}
              templateRows={"repeat(3, 1fr)"}
              gap={5}
            >
              <GridItem colSpan={1} rowSpan={3}>
                <VStack
                  bg="gray.100"
                  px={5}
                  py={3}
                  borderRadius="md"
                  boxShadow="lg"
                  spacing={6}
                  w="full"
                  maxW="md"
                >
                  <Heading size="sm" mb={2}>
                    Upload Cover Image
                    <Text as="span" color={"red.400"}>
                      *
                    </Text>
                  </Heading>
                  <Box>
                    {coverImage ? (
                      <Image
                        src={
                          coverImage.slice(0, 4) == "data"
                            ? coverImage
                            : `${process.env.REACT_APP_BASE_URL}/${coverImage}`
                        }
                        alt="Preview"
                        maxW="130px"
                        maxH="130px"
                      />
                    ) : (
                      <Text color="gray.500">No image selected</Text>
                    )}
                  </Box>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleCoverImage}
                    variant="filled"
                  />
                </VStack>
              </GridItem>
              <GridItem colSpan={1} rowSpan={3}>
                <VStack
                  bg="gray.100"
                  px={5}
                  py={3}
                  borderRadius="md"
                  boxShadow="lg"
                  spacing={6}
                  w="full"
                  maxW="md"
                >
                  <Heading size="sm" mb={2}>
                    Upload Short PDF
                  </Heading>
                  <Box>
                    {shortPdf ? (
                      <Image
                        src="/assets/pdf.svg"
                        alt="Preview"
                        maxW="130px"
                        maxH="130px"
                      />
                    ) : (
                      <Text color="gray.500">No PDF selected</Text>
                    )}
                  </Box>
                  <Input
                    type="file"
                    accept="application/pdf"
                    onChange={handlePdf}
                    variant="filled"
                  />
                </VStack>
              </GridItem>
              <GridItem colSpan={1} rowSpan={3}>
                {ebookSell == "ebook" || ebookSell == "both" ? (
                  <VStack
                    bg="gray.100"
                    px={5}
                    py={3}
                    borderRadius="md"
                    boxShadow="lg"
                    spacing={6}
                    w="full"
                    maxW="md"
                  >
                    <Heading size="sm" mb={2}>
                      Upload Ebook PDF
                    </Heading>
                    <Box>
                      {ebookPdf ? (
                        <Image
                          src="/assets/pdf.svg"
                          alt="Preview"
                          maxW="60px"
                          maxH="60px"
                        />
                      ) : (
                        <Text color="gray.500">No PDF selected</Text>
                      )}
                    </Box>
                    <Input
                      type="file"
                      accept="application/pdf"
                      onChange={handleEbook}
                      variant="filled"
                    />
                    {ebookPdf && (
                      <Input
                        placeholder="Price"
                        border={"1px solid red"}
                        value={ebookPrice || 0}
                        type="text"
                        onChange={(e) =>
                          setEbookPrice(Number(e?.target?.value))
                        }
                      />
                    )}
                  </VStack>
                ) : null}
              </GridItem>
              <GridItem colSpan={3} rowSpan={3}>
                <FormLabel>
                  Summary
                  <Text as="span" color={"red.400"}>
                    *
                  </Text>
                </FormLabel>
                <Textarea
                  h={135}
                  placeholder="Type your product summary"
                  {...register("summary", {
                    required: "This field is required",
                  })}
                />
                {errors.summary && (
                  <Text color={"tomato"}>{errors.summary.message}</Text>
                )}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Box textAlign={"center"}>
          <Button type="submit" colorScheme="blue" mt={20} mb={5} px={20}>
            Confirm
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditProduct;
