import {
  Box,
  Button,
  Grid,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import {
  getSettingsData,
  handleRemoveSlider,
  handleSettingsImageUpload,
} from "../services/axios.services";

export default function Settings() {
  const token = useSelector((state: RootState) => state.user.token);

  const [logoUpload, setLogoUpload] = useState("");
  const [firstBanner, setFirstBanner] = useState<string>("");
  const [firstBannerUrl, setFirstBannerUrl] = useState<string>("");
  const [secondBanner, setSecondBanner] = useState<string>("");
  const [secondBannerUrl, setSecondBannerUrl] = useState<string>("");
  const [thirdBanner, setThirdBanner] = useState<string>("");
  const [thirdBannerUrl, setThirdBannerUrl] = useState<string>("");
  const [fourthBanner, setFourthBanner] = useState<string>("");
  const [fourthBannerUrl, setFourthBannerUrl] = useState<string>("");
  const [sliders, setSliders] = useState<any[]>([]);
  const [newSlider, setNewSlider] = useState<string>("");
  const [newSliderLink, setNewSliderLink] = useState<string>("");

  const fetchData = async () => {
    const response = await getSettingsData();
    console.log("response -> ", response?.data);
    setLogoUpload(`${process.env.REACT_APP_BASE_URL}/${response?.data?.logo}`);
    setFirstBannerUrl(response?.data?.firstBanner?.url);
    setFirstBanner(
      `${process.env.REACT_APP_BASE_URL}/${response?.data?.firstBanner?.image}`
    );
    setSecondBannerUrl(response?.data?.secondBanner?.url);
    setSecondBanner(
      `${process.env.REACT_APP_BASE_URL}/${response?.data?.secondBanner?.image}`
    );
    setThirdBannerUrl(response?.data?.thirdBanner?.url);
    setThirdBanner(
      `${process.env.REACT_APP_BASE_URL}/${response?.data?.thirdBanner?.image}`
    );
    setFourthBannerUrl(response?.data?.fourthBanner?.url);
    setFourthBanner(
      `${process.env.REACT_APP_BASE_URL}/${response?.data?.fourthBanner?.image}`
    );
    setSliders(response?.data?.sliders);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const file = event.target.files?.[0];
    if (type === "logo" && file) {
      convertLogoToBase64(file);
    } else if (type === "firstBanner" && file) {
      convertFirstBannerToBase64(file);
    } else if (type === "secondBanner" && file) {
      convertSecondBannerToBase64(file);
    } else if (type === "thirdBanner" && file) {
      convertThirdBannerToBase64(file);
    } else if (type === "fourthBanner" && file) {
      convertFourthBannerToBase64(file);
    } else {
      convertSliderToBase64(file!);
    }
  };

  const convertLogoToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setLogoUpload(reader.result as string);
    };
  };

  const convertFirstBannerToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFirstBanner(reader.result as string);
    };
  };

  const convertSecondBannerToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSecondBanner(reader.result as string);
    };
  };

  const convertThirdBannerToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThirdBanner(reader.result as string);
    };
  };

  const convertFourthBannerToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFourthBanner(reader.result as string);
    };
  };

  const convertSliderToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setNewSlider(reader.result as string);
    };
  };

  const handleLogoSubmit = async () => {
    const response = await handleSettingsImageUpload(
      { logo: logoUpload },
      "logo"
    );
    toast(response.message);
  };

  async function handleBannerSubmit(bannerNumber: string) {
    if (bannerNumber === "firstBanner") {
      const response = await handleSettingsImageUpload(
        { firstBanner: { url: firstBannerUrl, image: firstBanner } },
        "banner"
      );
      toast(response.message);
    } else if (bannerNumber === "secondBanner") {
      const response = await handleSettingsImageUpload(
        { secondBanner: { url: secondBannerUrl, image: secondBanner } },
        "banner"
      );
      toast(response.message);
    } else if (bannerNumber === "thirdBanner") {
      const response = await handleSettingsImageUpload(
        { thirdBanner: { url: thirdBannerUrl, image: thirdBanner } },
        "banner"
      );
      toast(response.message);
    } else {
      const response = await handleSettingsImageUpload(
        { fourthBanner: { url: fourthBannerUrl, image: fourthBanner } },
        "banner"
      );
      toast(response.message);
    }
  }

  const handleSlidersSubmit = async () => {
    const response = await handleSettingsImageUpload(
      { sliders: [{ url: newSliderLink, image: newSlider }] },
      "sliders",
      token as string
    );
    if (response.success) {
      setNewSliderLink("");
      setNewSlider("");
      fetchData();
      toast.success(response.message);
    }
  };

  console.log("sliders -> ", sliders);

  const handleSliderRemove = async (id: string) => {
    const response = await handleRemoveSlider(id, token as string);
    fetchData();
    toast.success(response.message);
  };

  return (
    <Grid
      templateRows={"1fr 1fr"}
      templateColumns={[
        "1fr 1fr",
        "1fr 1fr 1fr",
        "1fr 1fr 1fr",
        "1fr 1fr 1fr 1fr",
      ]}
      gap={5}
      p={10}
    >
      {/* logo */}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload Logo
        </Heading>
        <Box>
          {logoUpload ? (
            <Image src={logoUpload} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "logo")}
          variant="filled"
        />
        <Button
          colorScheme="blue"
          onClick={handleLogoSubmit}
          isDisabled={!(logoUpload.slice(0, 4) == "data")}
          w="full"
        >
          Upload Logo
        </Button>
      </VStack>
      {/* first banner */}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload First Banner
        </Heading>
        <Box>
          {firstBanner ? (
            <Image src={firstBanner} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "firstBanner")}
          variant="filled"
        />
        <Input
          placeholder="Type URL for banner"
          size="md"
          value={firstBannerUrl}
          onChange={(event) => setFirstBannerUrl(event.target.value)}
        />
        <Button
          colorScheme="blue"
          onClick={() => handleBannerSubmit("firstBanner")}
          isDisabled={!(firstBanner.slice(0, 4) == "data")}
          w="full"
        >
          Upload Image
        </Button>
      </VStack>
      {/* second banner */}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload Second Banner
        </Heading>
        <Box>
          {secondBanner ? (
            <Image src={secondBanner} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "secondBanner")}
          variant="filled"
        />
        <Input
          placeholder="Type URL for banner"
          size="md"
          value={secondBannerUrl}
          onChange={(event) => setSecondBannerUrl(event.target.value)}
        />
        <Button
          colorScheme="blue"
          onClick={() => handleBannerSubmit("secondBanner")}
          isDisabled={!(secondBanner.slice(0, 4) == "data")}
          w="full"
        >
          Upload Image
        </Button>
      </VStack>
      {/* third banner */}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload Third Banner
        </Heading>
        <Box>
          {thirdBanner ? (
            <Image src={thirdBanner} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "thirdBanner")}
          variant="filled"
        />
        <Input
          placeholder="Type URL for banner"
          size="md"
          value={thirdBannerUrl}
          onChange={(event) => setThirdBannerUrl(event.target.value)}
        />
        <Button
          colorScheme="blue"
          onClick={() => handleBannerSubmit("thirdBanner")}
          isDisabled={!(thirdBanner.slice(0, 4) === "data")}
          w="full"
        >
          Upload Image
        </Button>
      </VStack>
      {/* fourth banner */}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload Fourth Banner
        </Heading>
        <Box>
          {fourthBanner ? (
            <Image src={fourthBanner} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "fourthBanner")}
          variant="filled"
        />
        <Input
          placeholder="Type URL for banner"
          size="md"
          value={fourthBannerUrl}
          onChange={(event) => setFourthBannerUrl(event.target.value)}
        />
        <Button
          colorScheme="blue"
          // onClick={() => handleBannerSubmit("fourthBanner")}
          onClick={() => handleBannerSubmit("fourthBanner")}
          isDisabled={!(fourthBanner.slice(0, 4) === "data")}
          w="full"
        >
          Upload Image
        </Button>
      </VStack>
      {/* sliders */}
      {sliders?.map((item, index) => (
        <VStack
          bg="gray.100"
          px={10}
          py={5}
          borderRadius="md"
          boxShadow="lg"
          spacing={6}
          w="full"
          maxW="md"
        >
          <Heading size="md" mb={2}>
            Slider {index + 1}
          </Heading>
          <Box>
            <Image
              src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`}
              alt="Preview"
              maxW="130px"
              maxH="130px"
            />
          </Box>
          <Input
            type="file"
            accept="image/*"
            onChange={(event) => handleImageUpload(event, "fourthBanner")}
            variant="filled"
            multiple
          />
          <Input
            placeholder="Type URL for banner"
            size="md"
            value={item?.url}
          />
          <Button
            colorScheme="blue"
            // onClick={() => handleBannerSubmit("fourthBanner")}
            onClick={() => handleSliderRemove(item?._id)}
            w="full"
          >
            Remove
          </Button>
        </VStack>
      ))}
      <VStack
        bg="gray.100"
        px={10}
        py={5}
        borderRadius="md"
        boxShadow="lg"
        spacing={6}
        w="full"
        maxW="md"
      >
        <Heading size="md" mb={2}>
          Upload Slider
        </Heading>
        <Box>
          {newSlider ? (
            <Image src={newSlider} alt="Preview" maxW="130px" maxH="130px" />
          ) : (
            <Text color="gray.500">No image selected</Text>
          )}
        </Box>
        <Input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, "sliders")}
          variant="filled"
          multiple
        />
        <Input
          placeholder="Type URL for slider"
          size="md"
          value={newSliderLink}
          onChange={(event) => setNewSliderLink(event.target.value)}
        />
        <Button
          colorScheme="blue"
          // onClick={() => handleBannerSubmit("fourthBanner")}
          onClick={handleSlidersSubmit}
          isDisabled={!newSlider || !newSliderLink}
          w="full"
        >
          Upload Slider
        </Button>
      </VStack>
    </Grid>
  );
}
